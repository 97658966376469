* {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}


:root {
    --secondary: #00c1fc;
    --primary: #ffda54;

    /* change with theme */
    --theme-shade: #fafafa;
    --close: #f5f5f5;
    --text-on-gender-color: #333;
    --closer: #eee;
    --farther: #555;
    --farthest: #333;
    --high-contrast: #444;

    --input-bg: #fafafa;
}

[data-theme="dark"] {
    --theme-shade: #121418;
    --close: #1d1f25;
    --text-on-gender-color: #1d1f25;
    --closer: #2c3039;
    --farther: #bbb;
    --farthest: #999;
    --high-contrast: #cfcfcf;
    
    --input-bg: #ccc;
}

[data-p-text-size="larger"] {
    --p-text-size: 1.3rem
}

[data-p-text-size="largest"] {
    --p-text-size: 1.6rem
}

body {
    background-color: var(--theme-shade);
    color: var(--high-contrast);
    line-height: 1.4;
    /* Needed because of fixed-top navbar
    padding-top: 75px;
    padding-bottom: 60px; */
}

.p-text {
    font-size: var(--p-text-size);
}

.text-on-gender-color {
    color: var(--text-on-gender-color)
}

pre {
    color: var(--high-contrast);
}

.card {
    background: var(--closer);
}

.block-card {
    background: var(--theme-shade);
    border-color: var(--farther);
}

.list-group {
    background: var(--closer);
}

.list-group-item {
    background: var(--closer);
}

hr {
    border-top: 1px solid var(--farther);
}

/* maybe call .box-alt? */
.bg-light {
    background-color: var(--closer) !important;
    color: var(--high-contrast);
}
.bg-white {
    background-color: var(--theme-shade) !important;
}

/* TODO: better handling of modals across light and dark modes */
.modal-body, .modal-title {
    color:#1d1f25;
}

.table {
    color: var(--high-contrast);
}

.width-limiter {
    max-width: 700px;
}

.thin-column {
    max-width: 1rem;
}

.entry {
    margin-bottom: 1.25rem;
}

.entry-extra-info {
    color: var(--farther);
    margin-left: 1rem;
    margin-top: 0.25rem;
}

.entry-definition {
    margin-top: 0.5rem;
    margin-left: 1rem;
}

kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px rgba(0, 0, 0,
            .2), 0 2px 0 0 rgba(255, 255, 255,
            .7) inset;
    color: #333;
    display: inline-block;
    font-size: .85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
}

.recording-banner {
    position: fixed;
    width: 100%;
    height: 16.5%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(244, 66, 66, 0.9);
    z-index: 2;
}

/* Remove blue glow thing */
textarea:focus,
input[type="text"]:focus,
input[type="search"]:focus,
button:focus {
    border-color: var(--farther);
    box-shadow: none;
    outline: 0 none;
}

.clickable {
    cursor: pointer;
}

.unclickable:hover {
    cursor: default !important;
}

.bottom-left {
    position: fixed;
    bottom: 2em;
    left: 2em;
}

.bottom-right {
    position: fixed;
    bottom: 2em;
    right: 2em;
}

.input-group {
    border-color: red !important;
}

input {
    background-color: var(--input-bg) !important;
}

.clear-search-button {
    background-color: var(--input-bg) !important;
}

.clear-search-button:hover {
    color: inherit;
    cursor: pointer;
    background-color: var(--input-bg) !important;
}

.clear-search-button:active {
    background-color: var(--input-bg) !important;
    color: var(--input-bg) !important;
}

.buttons-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 8px;
    padding-bottom: 5px;
}

@media screen and (max-width: 900px) {
    .buttons-footer {
        padding-left: 15%;
        padding-right: 15%
    }
}

@media screen and (max-width: 550px) {
    .buttons-footer {
        padding-left: 0;
        padding-right: 0;
    }
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 55px;
    left: 0;
    z-index: 500;
}

.footer-thick {
    height: 112px;
}

.wee-less-footer {
    height: 60px;
}

.form-control-clear:hover {
    cursor: pointer;
}

.theme-toggle-button {
    position: fixed;
    top: 90px;
    right: 30px;
    font-size: 20px;
}

.conjugation-search-button {
    position: fixed;
    top: 150px;
    right: 400px;
    z-index: 1000;
    border-radius: 32px;
}

.conjugation-search-button-with-bottom-searchbar {
    top: 110px;
}

.entry-suggestion-button {
    position: fixed;
    top: 208px;
    right: 400px;
    z-index: 1000;
    border-radius: 32px;
}

.entry-suggestion-button-with-bottom-searchbar {
    top: 168px;
}

@media screen and (max-width: 950px) {
    .entry-suggestion-button {
        right: 15px;
    }
    .conjugation-search-button {
        right: 15px;
    }
}

/* @media screen and (min-width: 800px) {
    .entry-suggestion-button {
        right: 200px;
    }
    .conjugation-search-button {
        right: 200px;
    }
} */

.bottom-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plain-link {
    color: inherit;
    text-decoration: none;
}

.plain-link:hover {
    text-decoration: none;
    color: var(--farther);
}
.clickable:hover {
    color: var(--farther);
}

.clear-search-button {
    background-color: white;
    margin-left: -2px;
    color: #444;
    border-color: var(--farther);
}

.clear-search-button:hover {
    color: #555;
}

.btn.bg-white:active,
.btn.bg-white:hover {
    color: #555 !important; 
}

.btn-group.full-width {
    display: flex;
}
  
.full-width .btn {
    flex: 1;
}

@media screen and (max-width: 550px) {
    .show-on-desktop {
        display: none;
    }
}

/* Loding animation from https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid var(--closer);
  border-right: 1.1em solid var(--closer);
  border-bottom: 1.1em solid var(--closer);
  border-left: 1.1em solid var(--farthest);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* End of loading animation from https://projects.lukehaas.me/css-loaders/ */
